import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { sample } from 'lodash'

import ChangeTitle from 'containers/change-title'
import PostListing from '../components/PostListing/PostListing'
import Describe from '../components/Describe/Describe'
import Header from '../components/Header/Header'
// import SEO from '../components/SEO/SEO'
import config from '../../data/SiteConfig'

class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props
    const itemEdges = data.allContentfulStory.edges.concat(
      data.allContentfulContext.edges
    )
    const selectStory = sample(data.allContentfulStory.edges).node
    const selectContext = sample(data.allContentfulContext.edges).node

    console.log(selectStory)
    // changeTitle('New American Story Project')

    return (
      <div className="index-container">
        <Helmet title={config.siteTitle} />
        {/* <SEO postEdges={storyEdges} /> */}
        <ChangeTitle title="New American Story Project" />
        <Header
          title="Welcome Children"
          subtitle="Stories of the Central American Refugee Crisis"
          image={data.headerImage}
        />
        <PostListing postEdges={itemEdges} />
        <Describe selectStory={selectStory} selectContext={selectContext} />
      </div>
    )
  }
}

export default IndexPage

/* eslint no-undef: "off" */
export const query = graphql`
  query IndexQuery {
    headerImage: file(relativePath: { regex: "/txwall1/" }) {
      childImageSharp {
        fluid(maxWidth: 3000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allContentfulStory {
      edges {
        node {
          id
          title
          slug
          type
          subject {
            name
            age
          }
          thumbnailImage {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          published
        }
      }
    }
    allContentfulContext {
      edges {
        node {
          id
          title
          slug
          type
          author {
            name
          }
          thumbnailImage {
            id
            sizes(maxWidth: 1800) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          published
        }
      }
    }
  }
`
