import styled from 'styled-components'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import { color, maxWidth } from 'styled-system'

export const Section = styled.div`
  ${color};
  padding: 8rem;
  border-bottom: 1px solid;
  @media only screen and (max-width: 62rem) {
    padding: 8rem 6rem;
  }
  @media only screen and (max-width: 48rem) {
    padding: 6rem 4rem;
  }
  @media only screen and (max-width: 32rem) {
    padding: 6rem 2rem;
  }
`

export const Wrapper = styled.div`
  ${maxWidth};
  margin: 0 auto;
`

export const Text = styled.p`
  font-family: 'GT Super Display', serif;
  font-size: 3rem;
  max-width: 20em;
  margin: 0 auto;
  &:not(:last-child) {
    margin-bottom: 1em;
  }
  a {
    text-decoration: none;
  }
  @media only screen and (max-width: 62rem) {
    font-size: 2.5rem;
  }
  @media only screen and (max-width: 48rem) {
    font-size: ${props => (props.large ? '2.5rem' : '2rem')};
  }
  @media only screen and (max-width: 48rem) {
    font-size: ${props => (props.large ? '2.5rem' : '1.5rem')};
  }
`

export const StyledLink = styled(Link)`
  color: inherit;
  font-weight: bold;
  background-image: linear-gradient(
    ${props =>
      props.theme.colors.black &&
      `${props.theme.colors.black}, ${props.theme.colors.black}`}
  );
  background-position: 0 1.05em;
  background-repeat: repeat-x;
  background-size: 1px 1px;
  ${props =>
    props.type === 'mono' &&
    `
      font-family: ${props.theme.fonts.monospace};
      text-decoration: none;
    `};
`
export const Chunk = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8rem;
  p {
    font-size: 2rem;
    font-family: ${props => props.theme.fonts.serif};
    margin-top: 0;
  }
  h3 {
    width: 33%;
    margin-top: 0.25rem;
    margin-right: 1rem;
    flex: none;
    font-size: 1.25rem;
    font-weight: 400;
    font-family: ${props => props.theme.fonts.monospace};
    text-transform: uppercase;
  }
  @media only screen and (max-width: 62rem) {
    flex-direction: column;
    h3 {
      margin-bottom: 3rem;
      font-size: 1.5rem;
      text-align: center;
      margin-right: 0;
      width: 100%;
    }
    p {
      font-size: 1.5rem;
    }
  }
`

export const Post = styled.div`
  margin: 3rem;
  transition: 0.25s;
  width: 50%;
  a {
    color: ${props => props.theme.colors.black};
    text-decoration: none;
  }
  &:hover {
    transform: scale(1.05);
  }
`

export const Image = styled(Img)`
  ${props =>
    props.ar >= 1
      ? `
    width: 22rem;
    height: 15rem;
    `
      : `
    width: 15rem;
    height: 22rem;
    `};
  margin: 0 auto;
`

export const Title = styled.h3`
  margin: 0 auto;
  margin-top: 1rem;
  font-family: ${props => props.theme.fonts.sansSerif};
  font-size: 1.25rem;
  font-weight: 300;
  text-decoration: none;
  text-align: center;
  max-width: 18em;
  color: ${props => props.theme.colors.black};
`

export const TypeWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;
  justify-content: ${props => props.pos};
`

export const Type = styled.p`
  margin: 0;
  font-family: ${props => props.theme.fonts.monospace};
  ${props => props.uppercase && 'text-transform: uppercase'};
  font-size: 0.875rem;
`

export const Divider = styled.hr`
  display: inline-block;
  height: 1px;
  width: 3rem;
  border: 0;
  margin: 0 1em;
  padding: 0;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px ${props => props.theme.colors.black} solid;
  }
`

export const Plus = styled.div`
  font-size: 2rem;
  font-weight: 100;
`

export const ExampleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 70rem;
  margin: 0 auto;
  @media only screen and (max-width: 48rem) {
    display: none;
  }
`
