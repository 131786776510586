import React from 'react'
import Link from 'gatsby-link'
import Sticky from 'react-sticky-el'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { take, head } from 'lodash'

import {
  Container,
  RecentList,
  Feature,
  Image,
  Post,
  FeatureImage,
  FeatureText,
  Title,
  Type,
  TypeWrapper,
  IconWrapper,
  FeatureWrapper,
  More,
  Divider,
} from './styles'

function getPostList(postEdges) {
  const postList = []
  postEdges.forEach(postEdge => {
    postList.push({
      path:
        postEdge.node.type === 'story'
          ? `/story/${postEdge.node.slug}`
          : `/context/${postEdge.node.slug}`,
      title: postEdge.node.title,
      type: postEdge.node.type,
      published: postEdge.node.published,
      image: postEdge.node.thumbnailImage.sizes,
      subject: postEdge.node.subject ? postEdge.node.subject : null,
      author: postEdge.node.author ? postEdge.node.author : null,
    })
  })
  postList.sort((a, b) => {
    const dateA = new Date(a.published)
    const dateB = new Date(b.published)
    return dateB - dateA
  })
  return take(postList, 7)
}

const PostListing = ({ postEdges }) => {
  const postList = getPostList(postEdges)
  const featuredPost = head(postList)
  return (
    <Container>
      <RecentList>
        {postList.map(post => (
          <Post key={post.title}>
            <Link to={post.path}>
              <Image sizes={post.image} ar={post.image.aspectRatio} />
              <TypeWrapper pos="center">
                <Type uppercase>{post.type}</Type>
                {post.subject &&
                  post.subject.length === 1 && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Divider />
                      <Type>{post.subject[0].name}</Type>
                    </div>
                  )}
              </TypeWrapper>
              <Title>{post.title}</Title>
            </Link>
          </Post>
        ))}
        <More to="/content/">More Stories</More>
      </RecentList>
      <FeatureWrapper className="block">
        <Sticky boundaryElement=".block" hideOnBoundaryHit={false}>
          <Feature>
            <FeatureImage sizes={featuredPost.image} />
            <Link to={featuredPost.path}>
              <FeatureText>
                <div>
                  <TypeWrapper pos="flex-start">
                    <Type uppercase>{featuredPost.type}</Type>
                    {featuredPost.subject &&
                      featuredPost.subject.length === 1 && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Divider />
                          <Type>{featuredPost.subject[0].name}</Type>
                        </div>
                      )}
                    {featuredPost.author && featuredPost.author.length === 1 ? (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Divider />
                        <Type>{featuredPost.author[0].name}</Type>
                      </div>
                    ) : (
                      featuredPost.author &&
                      featuredPost.author.length > 1 && (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Divider />
                          <Type>Multiple Contributors</Type>
                        </div>
                      )
                    )}
                  </TypeWrapper>
                  <Title>{featuredPost.title}</Title>
                </div>
                <IconWrapper>
                  <FontAwesomeIcon icon={faArrowRight} size="2x" />
                </IconWrapper>
              </FeatureText>
            </Link>
          </Feature>
        </Sticky>
      </FeatureWrapper>
    </Container>
  )
}

export default PostListing
