import React from 'react'

import { Container, Text, Title, Subtitle, BgImage, Gradient } from './styles'

const Header = ({ title, subtitle, image }) => (
  <Container>
    <BgImage fluid={image.childImageSharp.fluid} />
    <Gradient />
    <Text>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Text>
  </Container>
)

export default Header
