import styled from 'styled-components'
import Img from 'gatsby-image'

export const Container = styled.div`
  background: ${props => props.theme.colors.black};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 80vh;
  position: relative;
  .gatsby-image-outer-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
  @media only screen and (max-width: 48rem) {
  }
  @media only screen and (max-width: 32rem) {
    height: 50vh;
  }
`

export const BgImage = styled(Img)`
  width: 100%;
  height: 100%;
  background: none;
`
export const Gradient = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7));
`

export const Title = styled.h1`
  font-family: 'GT Super Display', serif;
  font-size: 7rem;
  font-weight: 400;
  margin: 0;
  color: ${props => props.theme.colors.white};
  line-height: 1;
  @media only screen and (max-width: 48rem) {
    font-size: 11vw;
  }
  @media only screen and (max-width: 32rem) {
    font-size: 18vw;
  }
`

export const Subtitle = styled.p`
  font-size: 2rem;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-weight: 300;
  color: ${props => props.theme.colors.white};
  @media only screen and (max-width: 48rem) {
    font-size: 3.75vw;
  }
`

export const Text = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 4rem;
  @media only screen and (max-width: 48rem) {
    margin: 2rem;
  }
`
