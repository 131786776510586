import React from 'react'
import Link from 'gatsby-link'

import {
  Section,
  Text,
  Chunk,
  Wrapper,
  StyledLink,
  Post,
  Image,
  Title,
  Type,
  Divider,
  Plus,
  TypeWrapper,
  ExampleWrapper,
} from './styles'

const Describe = ({ selectStory, selectContext }) => (
  <div>
    <Section bg="black" color="white">
      <Text>
        Since 2014, over 250,000 unaccompanied immigrant children have fled
        their homes in Guatemala, El Salvador, and Honduras.
      </Text>
      <Text>
        The region has become one of the deadliest places in the world outside
        of a warzone. Gangs, organized crime, extortion, murder, and sexual
        violence are rampant. It is not safe to be a child there.
      </Text>
    </Section>
    <Section bg="white" color="black">
      <Text large>
        We are telling their{' '}
        <StyledLink to="/content?filter=stories">Stories</StyledLink> and
        working with experts to understand the{' '}
        <StyledLink to="/content?filter=context">Context</StyledLink>.
      </Text>
      <ExampleWrapper>
        <Post key={selectStory.title}>
          <Link to={`/story/${selectStory.slug}`}>
            <Image
              sizes={selectStory.thumbnailImage.sizes}
              ar={selectStory.thumbnailImage.sizes.aspectRatio}
            />
            <TypeWrapper pos="center">
              <Type uppercase>{selectStory.type}</Type>
              {selectStory.subject &&
                selectStory.subject.length === 1 && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Divider />
                    <Type>{selectStory.subject[0].name}</Type>
                  </div>
                )}
            </TypeWrapper>
            <Title>{selectStory.title}</Title>
          </Link>
        </Post>
        <Plus>+</Plus>
        <Post key={selectContext.title}>
          <Link to={`/context/${selectContext.slug}`}>
            <Image
              sizes={selectContext.thumbnailImage.sizes}
              ar={selectContext.thumbnailImage.sizes.aspectRatio}
            />
            <TypeWrapper pos="center">
              <Type uppercase>{selectContext.type}</Type>
              {selectContext.author && selectContext.author.length === 1 ? (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Divider />
                  <Type>{selectContext.author[0].name}</Type>
                </div>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Divider />
                  <Type>Multiple Contributors</Type>
                </div>
              )}
            </TypeWrapper>
            <Title>{selectContext.title}</Title>
          </Link>
        </Post>
      </ExampleWrapper>
    </Section>
    <Section bg="white" color="black">
      <Wrapper maxWidth="80rem">
        <Chunk>
          <h3>About this project</h3>
          <div>
            <p>
              Our current project,{' '}
              <i>
                Welcome Children: Voices of the Central American Refugee Crisis
              </i>{' '}
              focuses on unaccompanied Central American minors who journeyed
              thousands of miles to reach the U.S. They tell their stories in
              their own words while legal, social science and policy experts
              provide perspective, up-to-date data and detailed context. We hope
              to foster greater understanding about the ongoing humanitarian
              crises in Central America.
            </p>
            <StyledLink to="/about" type="mono">
              Read more about the organization.
            </StyledLink>
          </div>
        </Chunk>
        {/*<Chunk>
          <h3>Why?</h3>
          <div>
            <p>
              We want you to see them—refugees in our community—and ask, “Who
              are they? Why are they here? What are their stories? How can I
              support immigrants in my own community?”
            </p>
            <StyledLink to="/action" type="mono">
              Learn more about how you can help.
            </StyledLink>
          </div>
        </Chunk>*/}
      </Wrapper>
    </Section>
  </div>
)

export default Describe
