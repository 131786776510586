import styled from 'styled-components'
import Img from 'gatsby-image'
import Link from 'gatsby-link'

export const Container = styled.div`
  display: flex;
  position: relative;
  @media only screen and (max-width: 48rem) {
    flex-direction: column;
  }
`

export const RecentList = styled.div`
  width: 50%;
  text-align: center;
  margin-bottom: 8rem;
  @media only screen and (max-width: 48rem) {
    width: 100%;
    order: 2;
  }
`
export const Post = styled.div`
  margin: 6rem;
  transition: 0.25s;

  &:first-child {
    display: none;
    @media only screen and (max-width: 48rem) {
      display: block;
    }
  }
  a {
    color: ${props => props.theme.colors.black};
    text-decoration: none;
  }
  &:hover {
    transform: scale(1.05);
  }
  @media only screen and (max-width: 62rem) {
    margin: 6rem auto;
    padding: 0 4rem;
    max-width: 26rem;
  }
`

export const Image = styled(Img)`
  ${props =>
    props.ar >= 1
      ? `
    width: 22rem;
    height: 15rem;
    `
      : `
    width: 15rem;
    height: 22rem;
    `};
  margin: 0 auto;
  @media only screen and (max-width: 80rem) {
    width: auto;
    height: auto;
  }
`

export const FeatureImage = styled(Img)`
  width: 100%;
  height: 100vh;
`

export const Title = styled.h3`
  margin: 0 auto;
  margin-top: 1rem;
  font-family: ${props => props.theme.fonts.serif};
  font-size: 2rem;
  font-weight: 300;
  text-decoration: none;
  max-width: 18em;
  color: ${props => props.theme.colors.black};
  @media only screen and (max-width: 62rem) {
    font-size: 1.5rem;
  }
`

export const TypeWrapper = styled.div`
  display: flex;
  margin-top: 1rem;
  align-items: center;
  justify-content: ${props => props.pos};
`

export const More = styled(Link)`
  font-family: ${props => props.theme.fonts.monospace};
  ${props => props.uppercase && 'text-transform: uppercase'};
  font-size: 1rem;
  padding: 1rem 2rem;
  width: auto;
  color: ${props => props.theme.colors.black};
  border: 1px solid ${props => props.theme.colors.black};
`

export const IconWrapper = styled.div`
  color: ${props => props.theme.colors.black};
  height: 100%;
`

export const Type = styled.div`
  margin: 0;
  font-family: ${props => props.theme.fonts.monospace};
  ${props => props.uppercase && 'text-transform: uppercase'};
  font-size: 0.875rem;
  color: ${props => props.theme.colors.black};
  @media only screen and (max-width: 62rem) {
    font-size: 0.75rem;
  }
`

export const FeatureText = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 2rem;
  right: 2rem;
  background: ${props => props.theme.colors.white};
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: 0.25s;
  a {
    color: ${props => props.theme.colors.black};
    text-decoration: none;
  }
  &:hover {
    & > * {
      opacity: 0.7;
    }
  }
`

export const Feature = styled.div`
  background: lightgrey;
  width: 100%;
  height: 100vh;
  position: relative;
`

export const FeatureWrapper = styled.div`
  width: 50%;
  position: relative;
  @media only screen and (max-width: 48rem) {
    display: none;
  }
`

export const Divider = styled.hr`
  display: inline-block;
  height: 1px;
  width: 3rem;
  border: 0;
  margin: 0 1em;
  padding: 0;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px ${props => props.theme.colors.black} solid;
  }
`
